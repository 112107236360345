import { ProfileService as ProfileServiceFromCore, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ProfileDto, UpdateProfileDto } from '../models/account';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends ProfileServiceFromCore {
  apiName = 'AbpIdentity';

  getTwoFactorEnabled = () =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `/api/identity/my-profile/two-factor-enabled`,
      },
      { apiName: this.apiName },
    );

  setTwoFactorEnabled = (enabled: boolean) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/identity/my-profile/set-two-factor-enabled`,
        params: { enabled },
      },
      { apiName: this.apiName },
    );

  update = (input: UpdateProfileDto) =>
    this.restService.request<any, ProfileDto>(
      {
        method: 'PUT',
        url: `/api/identity/my-profile`,
        body: input,
      },
      { apiName: this.apiName },
    );

  constructor(private restService: RestService) {
    super(restService);
  }
}
