<form
  *ngIf="!isPasswordReset; else passwordResetTemplate"
  [formGroup]="form"
  [mapErrorsFn]="mapErrorsFn"
  (ngSubmit)="onSubmit()"
  validateOnSubmit
>
  <p>{{ 'AbpAccount::ResetPassword_Information' | abpLocalization }}</p>
  <div class="form-group">
    <label for="input-password">{{ 'AbpAccount::Password' | abpLocalization }}</label
    ><span> * </span>
    <input type="password" id="input-password" class="form-control" formControlName="password" />
  </div>
  <div class="form-group">
    <label for="input-confirm-password">{{ 'AbpAccount::ConfirmPassword' | abpLocalization }}</label
    ><span> * </span>
    <input
      type="password"
      id="input-confirm-password"
      class="form-control"
      formControlName="confirmPassword"
    />
  </div>
  <button class="mr-2 btn btn-secondary" type="button">
    {{ 'AbpAccount::Cancel' | abpLocalization }}
  </button>
  <abp-button buttonClass="mr-2 btn btn-primary" [loading]="inProgress" (click)="onSubmit()">
    {{ 'AbpAccount::Submit' | abpLocalization }}
  </abp-button>
</form>

<ng-template #passwordResetTemplate>
  <p>
    {{ 'AbpAccount::YourPasswordIsSuccessfullyReset' | abpLocalization }}
  </p>

  <a routerLink="/account/login">
    <button class="d-block mt-2 mb-3 btn btn-primary">
      {{ 'AbpAccount::BackToLogin' | abpLocalization }}
    </button>
  </a>
</ng-template>
