import { ConfigStateService, ProfileService, SubscriptionService } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Component, EmbeddedViewRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ProfileResponse } from '../../models/profile';
import { AccountService } from '../../services/account.service';
import { ManageProfileStateService } from '../../services/manage-profile-state.service';

const { maxLength, required, email } = Validators;

@Component({
  selector: 'abp-personal-settings-form',
  templateUrl: './personal-settings.component.html',
  providers: [SubscriptionService],
})
export class PersonalSettingsComponent implements OnDestroy, OnInit {
  storedProfile: ProfileResponse;

  profile$: Observable<ProfileResponse> = this.manageProfileState.getProfile$();

  modalVisible = false;

  modalBusy: boolean = false;

  modalRef: EmbeddedViewRef<any>;

  form: FormGroup;

  token: string = '';

  isEmailUpdateEnabled: boolean = true;
  isUserNameUpdateEnabled: boolean = true;

  showEmailVerificationBtn$ = this.manageProfileState.createStateStream(
    state => !state.hideEmailVerificationBtn,
  );

  get userId(): string {
    return this.configState.getDeep('currentUser.id');
  }

  askForImmediatePhoneNumberVerification = () => {
    this.confirmationService
      .info('AbpAccount::DoYouWantToVerifyPhoneNumberMessage', 'AbpAccount::ConfirmYourPhoneNumber')
      .pipe(filter(status => status === Confirmation.Status.confirm))
      .subscribe(this.initPhoneNumberConfirmation);
  };

  checkPhoneNumberChanges = ({ phoneNumber }: ProfileResponse) => {
    if (phoneNumber && phoneNumber !== this.storedProfile?.phoneNumber) {
      this.askForImmediatePhoneNumberVerification();
    }
  };

  buildForm = (profile: ProfileResponse) => {
    this.form = this.fb.group({
      userName: [profile.userName, [required, maxLength(256)]],
      email: [profile.email, [required, email, maxLength(256)]],
      name: [profile.name || '', [maxLength(64)]],
      surname: [profile.surname || '', [maxLength(64)]],
      phoneNumber: [profile.phoneNumber || '', [maxLength(16)]],
    });
  };

  initPhoneNumberConfirmation = () => {
    this.accountService
      .sendPhoneNumberConfirmationToken({
        phoneNumber: this.form.value.phoneNumber,
        userId: this.userId,
      })
      .pipe(tap(() => (this.token = '')))
      .subscribe(this.openModal);
  };

  openModal = () => {
    this.modalVisible = true;
  };

  removeModal = () => {
    this.modalVisible = false;
  };

  setPhoneNumberAsConfirmed = () => {
    this.storedProfile.phoneNumberConfirmed = true;
  };

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toasterService: ToasterService,
    private confirmationService: ConfirmationService,
    private configState: ConfigStateService,
    private subscription: SubscriptionService,
    private manageProfileState: ManageProfileStateService,
    private profileService: ProfileService,
  ) {}

  confirmPhoneNumber() {
    this.accountService
      .confirmPhoneNumber({ token: this.token, userId: this.userId })
      .pipe(tap(this.setPhoneNumberAsConfirmed), tap(this.removeModal))
      .subscribe(() => {
        this.toasterService.success('AbpAccount::Verified', '', { life: 5000 });
      });
  }

  sendEmailVerificationToken() {
    this.accountService
      .sendEmailConfirmationToken({
        appName: 'Angular',
        email: this.form.value.email,
        userId: this.userId,
      })
      .subscribe(() => {
        this.toasterService.success('AbpAccount::EmailConfirmationSentMessage', '', {
          messageLocalizationParams: [this.form.value.email],
        });

        this.manageProfileState.setHideEmailVerificationBtn(true);
      });
  }

  ngOnDestroy() {
    this.removeModal();
  }

  ngOnInit() {
    this.subscription.addOne(
      this.profile$.pipe(
        filter<ProfileResponse>(Boolean),
        tap(profile => (this.storedProfile = profile)),
        tap(this.checkPhoneNumberChanges),
      ),
      this.buildForm,
    );

    const settings = this.configState.getSettings();
    this.isEmailUpdateEnabled =
      (settings['Abp.Identity.User.IsEmailUpdateEnabled'] || '').toLowerCase() !== 'false';
    this.isUserNameUpdateEnabled =
      (settings['Abp.Identity.User.IsUserNameUpdateEnabled'] || '').toLowerCase() !== 'false';
  }

  submit() {
    if (this.form.invalid) return;

    const { phoneNumberConfirmed, ...profile } = this.form.value;

    this.profileService.update(profile).subscribe(res => {
      this.manageProfileState.setProfile(res);
      this.toasterService.success('AbpAccount::PersonalSettingsSaved', '', { life: 5000 });
    });
  }
}
