import type { CoveredEntityDto, CreateCoveredEntityDto, GetCoveredEntitiesInput, IssueCardsDto, IssuedCardsPerRoundConfigurationDto, ProcessingControlNumberDto, UpdateCoveredEntityDto } from './models';
import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CardDto } from '../cards/models';

@Injectable({
  providedIn: 'root',
})
export class CoveredEntityCrudService {
  apiName = 'Default';

  create = (input: CreateCoveredEntityDto) =>
    this.restService.request<any, CoveredEntityDto>({
      method: 'POST',
      url: '/api/app/covered-entity-crud',
      body: input,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, CoveredEntityDto>({
      method: 'GET',
      url: `/api/app/covered-entity-crud/${id}`,
    },
    { apiName: this.apiName });

  getAll = () =>
    this.restService.request<any, ListResultDto<CoveredEntityDto>>({
      method: 'GET',
      url: '/api/app/covered-entity-crud/all',
    },
    { apiName: this.apiName });

  getByTenantId = (id: string) =>
    this.restService.request<any, CoveredEntityDto>({
      method: 'GET',
      url: `/api/app/covered-entity-crud/by-tenant-id/${id}`,
    },
    { apiName: this.apiName });

  getHighestCardNumber = () =>
    this.restService.request<any, CardDto>({
      method: 'GET',
      url: '/api/app/covered-entity-crud/highest-card-number',
    },
    { apiName: this.apiName });

  getIssuedCardsPerRoundConfiguration = () =>
    this.restService.request<any, IssuedCardsPerRoundConfigurationDto>({
      method: 'GET',
      url: '/api/app/covered-entity-crud/issued-cards-per-round-configuration',
    },
    { apiName: this.apiName });

  getList = (input: GetCoveredEntitiesInput) =>
    this.restService.request<any, PagedResultDto<CoveredEntityDto>>({
      method: 'GET',
      url: '/api/app/covered-entity-crud',
      params: { filterText: input.filterText, name: input.name, nec: input.nec, group: input.group, address1: input.address1, address2: input.address2, city: input.city, state: input.state, zip: input.zip, bin: input.bin, primaryColor: input.primaryColor, secondaryColor: input.secondaryColor, horizontalLogo: input.horizontalLogo, circleLogo: input.circleLogo, phoneNumber: input.phoneNumber, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getProcessingControlNumbers = () =>
    this.restService.request<any, ListResultDto<ProcessingControlNumberDto>>({
      method: 'GET',
      url: '/api/app/covered-entity-crud/processing-control-numbers',
    },
    { apiName: this.apiName });

  issueCards = (input: IssueCardsDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/covered-entity-crud/issue-cards',
      body: input,
    },
    { apiName: this.apiName });

  update = (id: string, input: UpdateCoveredEntityDto) =>
    this.restService.request<any, CoveredEntityDto>({
      method: 'PUT',
      url: `/api/app/covered-entity-crud/${id}`,
      body: input,
    },
    { apiName: this.apiName });
    
  constructor(private restService: RestService) {}
}
