<ng-container *ngIf="!edited; else editedTemplate">
  <span class="input-group-text" *ngIf="verified; else verifyButton">
    <i class="mr-1 text-success fa fa-check-square" aria-hidden="true"> </i
    ><span class="text-success">{{
      verifiedLabel || 'AbpAccount::Verified' | abpLocalization
    }}</span>
  </span>

  <ng-template #verifyButton>
    <button type="button" class="btn btn-warning" (click)="onBtnClick()">
      <i class="mr-1 fa fa-vcard" aria-hidden="true"></i>
      {{ buttonLabel || 'AbpAccount::Verify' | abpLocalization }}
    </button>
  </ng-template>
</ng-container>

<ng-template #editedTemplate>
  <span
    class="input-group-text"
    placement="top"
    [ngbTooltip]="editedTooltip || 'AbpAccount::FirstlySubmitToVerify' | abpLocalization"
  >
    <i class="mr-1 fa fa-times" aria-hidden="true"> </i>
    <span class="text-danger">
      {{ editedLabel || 'AbpAccount::NotVerified' | abpLocalization }}
    </span>
  </span>
</ng-template>
