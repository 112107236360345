<header
  *ngIf="{
    isNavbarExpanded: isNavbarExpanded$ | async,
    isMenuExpanded: isMenuExpanded$ | async,
    smallScreen: smallScreen$ | async,
    isMenuPlacementTop: isMenuPlacementTop$ | async
  } as data"

  
>

  <div [hidden]="!this.legacyHeader">
    <abp-navbar-mobile
      *abpReplaceableTemplate="{
        componentKey: navbarMobileComponentKey,
        inputs: {
          isNavbarExpanded: { value: data.isNavbarExpanded },
          isMenuExpanded: { value: data.isMenuExpanded }
        },
        outputs: {
          navbarIconClick: navbarIconClick,
          menuIconClick: menuIconClick
        }
      }"
      [isNavbarExpanded]="data.isNavbarExpanded"
      [isMenuExpanded]="data.isMenuExpanded"
      (navbarIconClick)="navbarIconClick($event)"
      (menuIconClick)="menuIconClick($event)"
    ></abp-navbar-mobile>

    <abp-navbar
      *abpReplaceableTemplate="{
        componentKey: navbarComponentKey,
        inputs: {
          isNavbarExpanded: { value: data.isNavbarExpanded },
          smallScreen: { value: data.smallScreen }
        }
      }"
      [smallScreen]="data.smallScreen"
      [isNavbarExpanded]="data.isNavbarExpanded"
    >
    </abp-navbar>
    <abp-sidebar
      *abpReplaceableTemplate="{
        componentKey: sidebarComponentKey,
        inputs: {
          smallScreen: { value: data.smallScreen },
          isMenuExpanded: { value: data.isMenuExpanded },
          isMenuPlacementTop: { value: data.isMenuPlacementTop }
        },
        outputs: { mouseMoveContainer: mouseMoveContainer.next }
      }"
      [smallScreen]="data.smallScreen"
      [isMenuExpanded]="data.isMenuExpanded"
      [isMenuPlacementTop]="data.isMenuPlacementTop"
      (mouseMoveContainer)="mouseMoveContainer.next($event)"
    ></abp-sidebar>
  </div>

  <div [hidden]="this.legacyHeader">
    <div class="account-header fixed-top pb-0 mb-15">
      <div class="healthvine__header">
        <div class="text-right">
          <span class="ml-2 mr-5">
            <ng-container *ngIf="currentUser$ | async as user">
              <small *ngIf="(selectedTenant$ | async)?.name as tenantName">
                {{ tenantName }} - Need help? 1-888-345-5745
              </small>

              <small *ngIf="user.isAuthenticated && !(selectedTenant$ | async)?.name as tenantName">
                healthvine_master - Need help? 1-888-345-5745
              </small>

              <small *ngIf="!user.isAuthenticated" class="text-white m-5">
                Need help? 1-888-345-5745
              </small>
            </ng-container>
          </span>
          
        </div>
      </div>
      <div class="bg-white healthvine__header-logo">
        <div class="row">
          <div class="col-8 col-md-7 col-lg-8 col-xl-9">
            <div class="text-left">
              <a href="/">
                <img src="/assets/images/logo/logo-light.png" height="60px"/>
              </a>
            </div>
          </div>
          <ng-container *ngIf="currentUser$ | async as user">
            <div class="col-4 col-md-5 col-lg-4 col-xl-3" *ngIf="user.isAuthenticated">
              <ul class="row">
                <div ngbDropdown class="col-12 col-lg-11 text-right">
                  <a id="dropdownAdministration" class="text-dark" ngbDropdownToggle>Administration</a>
                  <div ngbDropdownMenu aria-labelledby="dropdownAdministration">
                    <a *ngIf="(selectedTenant$ | async)?.isAvailable && (selectedTenant$ | async)?.name !== 'Patients'" href="/patients" ngbDropdownItem>Patients</a>
                    <a *ngIf="!(selectedTenant$ | async)?.isAvailable" href="/identity/users" ngbDropdownItem>Admin Users</a>
                    
                    
                    <!--
                    <a *ngIf="!(selectedTenant$ | async)?.isAvailable" href="/saas/tenants" ngbDropdownItem>Covered Entity Admin Users</a>
                    -->

                    <a *ngIf="!(selectedTenant$ | async)?.isAvailable" href="/covered-entities" ngbDropdownItem>Covered Entity Management</a>
                    <a *ngIf="(selectedTenant$ | async)?.name === 'Patients'" href="/setting-management" ngbDropdownItem>Settings</a>
                    <a *ngIf="(selectedTenant$ | async)?.name === 'Patients'" href="/text-template-management/text-templates" ngbDropdownItem>Text Templates</a>
                    <a (click)="navbarLogoutClick()" ngbDropdownItem>Logout</a>
                  </div>
                </div>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <br/>
  </div>
</header>
