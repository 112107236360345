import { Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Layout } from '../models';
import { setStyle } from '../utils/layout.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { LayoutStateService } from './layout-state.service';
import { CUSTOM_STYLE } from '../tokens/custom-style.token';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private renderer: Renderer2;
  private rendererFactory: RendererFactory2;
  private themeSettingsService: SettingsService;
  private layoutState: LayoutStateService;

  constructor(private injector: Injector) {
    this.rendererFactory = injector.get(RendererFactory2);
    this.themeSettingsService = injector.get(SettingsService);
    this.layoutState = injector.get(LayoutStateService);
    this.renderer = this.rendererFactory.createRenderer(document.body, null);
  }

  fetchThemeSettings(successFn?: (data: Layout.ThemeSettings) => void) {
    this.themeSettingsService.get().subscribe(themeSettings => {
      const customStyle = this.injector.get(CUSTOM_STYLE);
      if (!customStyle) {
        setStyle((themeSettings.style || 0) + 1, this.injector);
      }
      this.setMenuPlacement(themeSettings.menuPlacement || 0);
      this.setLayoutBoxed(themeSettings.boxedLayout || false);
      if ((themeSettings.menuPlacement || 0) === Layout.MenuPlacement.Left) {
        this.setMenuStatus(themeSettings.menuStatus || 0);
      }
      this.layoutState.patch({ themeSettings });
      if (successFn) {
        successFn(themeSettings);
      }
    });
  }

  updateThemeSettings(
    themeSettings: Layout.ThemeSettings,
    successFn?: (data: Layout.ThemeSettings) => void,
    errorFn?: (error: HttpErrorResponse) => Observable<any>,
  ) {
    this.themeSettingsService
      .update(themeSettings)
      .pipe(catchError(e => (errorFn ? errorFn(e) : throwError(e))))
      .subscribe(data => {
        this.layoutState.patch({ themeSettings });
        if (successFn) {
          successFn(data);
        }
      });
  }

  setLayoutBoxed(boxedLayout: boolean) {
    if (this.layoutState.get('boxedLayout') === boxedLayout) {
      return;
    }
    if (boxedLayout) {
      this.renderer.addClass(document.body, 'lp-boxed');
    } else {
      this.renderer.removeClass(document.body, 'lp-boxed');
    }
    this.layoutState.patch({ boxedLayout });
  }

  setMenuStatus(menuStatus: Layout.MenuStatus) {
    if (
      this.layoutState.get('menuStatus') === menuStatus &&
      this.layoutState.get('menuPlacement') !== Layout.MenuPlacement.Left
    ) {
      return;
    }
    this.addMenuStatusClasses(menuStatus);
    this.layoutState.patch({ menuStatus, isSidebarCollapsed: !!menuStatus });
  }

  setMenuPlacement(menuPlacement: Layout.MenuPlacement) {
    if (this.layoutState.get('menuPlacement') === menuPlacement) {
      return;
    }
    this.addMenuPlacementClasses(menuPlacement);
    this.layoutState.patch({
      menuPlacement,
      isMenuPlacementTop: menuPlacement === Layout.MenuPlacement.Top,
    });
  }

  onClickMenuIcon(value: boolean) {
    this.layoutState.patch({ isSidebarCollapsed: !value });
    if (value) {
      this.setMenuStatus(Layout.MenuStatus.AlwaysOpened);
    } else {
      this.setMenuStatus(Layout.MenuStatus.OpenOnHover);
    }
  }

  addMenuPlacementClasses(menuPlacement: Layout.MenuPlacement) {
    if (menuPlacement === Layout.MenuPlacement.Top) {
      ['lp-opened-sidebar', 'lp-body-fixed', 'lp-closed'].forEach(lpClass =>
        this.renderer.removeClass(document.body, lpClass),
      );
      this.renderer.addClass(document.body, 'lp-topmenu');
    } else {
      this.renderer.removeClass(document.body, 'lp-topmenu');
      this.renderer.addClass(document.body, 'lp-opened-sidebar');
    }
  }

  addMenuStatusClasses(menuStatus: Layout.MenuStatus) {
    if (menuStatus) {
      this.renderer.removeClass(document.body, 'lp-body-fixed');
      this.renderer.removeClass(document.body, 'lp-opened-sidebar');
      this.renderer.addClass(document.body, 'lp-closed');
    } else {
      this.renderer.addClass(document.body, 'lp-body-fixed');
      this.renderer.addClass(document.body, 'lp-opened-sidebar');
      this.renderer.removeClass(document.body, 'lp-closed');
    }
  }

  addSidebarClasses(mouseOnSidebar) {
    if (mouseOnSidebar) {
      if (document.body.classList.contains('lp-closed')) {
        this.renderer.addClass(document.body, 'lp-extended');
      }
    } else {
      this.renderer.removeClass(document.body, 'lp-extended');
    }
  }

  /**
   * @deprecated
   * this method will be removed in v5.0
   * use changeMenuPlacementByScreenSize method instead.
   */
  setSmallScreen() {
    return new Observable(subscriber => {
      setTimeout(() => {
        const isMenuPlacementTop = this.layoutState.get('isMenuPlacementTop');
        const smallScreen = window.innerWidth < 992;
        if (smallScreen) {
          this.addMenuPlacementClasses(Layout.MenuPlacement.Left);
        } else if (!smallScreen && isMenuPlacementTop) {
          this.addMenuPlacementClasses(Layout.MenuPlacement.Top);
        }
        this.layoutState.patch({ smallScreen });
        subscriber.next();
        subscriber.complete();
      }, 0);
    });
  }

  changeMenuPlacementByScreenSize() {
    const isMenuPlacementTop = this.layoutState.get('isMenuPlacementTop');
    const smallScreen = window.innerWidth < 992;
    if (smallScreen) {
      this.addMenuPlacementClasses(Layout.MenuPlacement.Left);
    } else if (!smallScreen && isMenuPlacementTop) {
      this.addMenuPlacementClasses(Layout.MenuPlacement.Top);
    }
    this.layoutState.patch({ smallScreen });
  }
}
