<form validateOnSubmit *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <div class="form-group">
    <label for="username">{{ 'AbpIdentity::DisplayName:UserName' | abpLocalization }}</label
    ><span> * </span
    ><input
      type="text"
      id="username"
      class="form-control"
      formControlName="userName"
      autofocus
      (keydown.space)="$event.preventDefault()"
      [readonly]="!isUserNameUpdateEnabled"
    />
  </div>
  <div class="row">
    <div class="col col-md-6">
      <div class="form-group">
        <label for="name">{{ 'AbpIdentity::DisplayName:Name' | abpLocalization }}</label
        ><input type="text" id="name" class="form-control" formControlName="name" />
      </div>
    </div>
    <div class="col col-md-6">
      <div class="form-group">
        <label for="surname">{{ 'AbpIdentity::DisplayName:Surname' | abpLocalization }}</label
        ><input type="text" id="surname" class="form-control" formControlName="surname" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="email-address">{{ 'AbpIdentity::DisplayName:Email' | abpLocalization }}</label
    ><span> * </span>
    <div class="input-group" validationTarget validationStyle>
      <input
        type="text"
        id="email-address"
        class="form-control"
        formControlName="email"
        [readonly]="!isEmailUpdateEnabled"
      />
      <div
        *ngIf="{
          edited: form.value.email !== storedProfile?.email,
          confirmed: storedProfile?.emailConfirmed
        } as data"
        class="input-group-append"
      >
        <abp-personal-settings-verify-button
          *ngIf="(showEmailVerificationBtn$ | async) || data.edited || data.confirmed"
          [verified]="data.confirmed"
          [edited]="data.edited"
          (btnClick)="sendEmailVerificationToken()"
        ></abp-personal-settings-verify-button>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="phone-number">{{ 'AbpIdentity::DisplayName:PhoneNumber' | abpLocalization }}</label>
    <div class="input-group mb-3">
      <input type="text" id="phone-number" class="form-control" formControlName="phoneNumber" />
      <div class="input-group-append" *ngIf="storedProfile?.phoneNumber">
        <abp-personal-settings-verify-button
          [verified]="storedProfile?.phoneNumberConfirmed"
          [edited]="form.value.phoneNumber !== storedProfile?.phoneNumber"
          (btnClick)="initPhoneNumberConfirmation()"
        ></abp-personal-settings-verify-button>
      </div>
    </div>
  </div>
  <abp-button
    iconClass="fa fa-check"
    buttonClass="btn btn-primary color-white"
    buttonType="submit"
    [disabled]="form?.invalid"
  >
    {{ 'AbpIdentity::Save' | abpLocalization }}</abp-button
  >
</form>

<abp-modal [(visible)]="modalVisible" [busy]="modalBusy" size="sm">
  <ng-template #abpHeader>
    <h5>{{ 'AbpAccount::Verify' | abpLocalization }}</h5>
  </ng-template>
  <ng-template #abpBody>
    <form (ngSubmit)="confirmPhoneNumber()">
      <div class="mt-2">
        <p>
          {{ 'AbpAccount::ConfirmationTokenSentMessage' | abpLocalization: form.value.phoneNumber }}
        </p>
        <div class="form-group">
          <label for="confirm-phone-number">{{
            'AbpAccount::PhoneConfirmationToken' | abpLocalization
          }}</label>
          <input
            [(ngModel)]="token"
            id="confirm-phone-number"
            name="confirm-phone-number"
            class="form-control"
            type="text"
            autofocus
          />
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #abpFooter>
    <button abpClose type="button" class="btn btn-secondary">
      {{ 'AbpAccount::Cancel' | abpLocalization }}
    </button>
    <abp-button type="abp-button" iconClass="fa fa-check" (click)="confirmPhoneNumber()">
      {{ 'AbpAccount::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
