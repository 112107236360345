<ng-container *ngFor="let alert of service.alerts$ | async; let i = index">
  <div
    class="alert alert-{{ alert.type }} fade show"
    [ngClass]="{ 'alert-dismissible fade show': alert.dismissible }"
    role="alert"
  >
    <h4 class="alert-heading" *ngIf="alert.title">
      {{ alert.title | abpLocalization: alert.titleLocalizationParams }}
    </h4>
    {{ alert.message | abpLocalization: alert.messageLocalizationParams }}
    <button
      *ngIf="alert.dismissible"
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
      (click)="service.remove(i)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</ng-container>
