import {
  AuthService,
  ConfigStateService,
  EnvironmentService,
  NAVIGATE_TO_MANAGE_PROFILE,
  SessionStateService,
} from '@abp/ng.core';
import { Component, Inject, Optional } from '@angular/core';
import {
  NAVIGATE_TO_MY_SECURITY_LOGS,
  OPEN_MY_LINK_USERS_MODAL,
} from '@volo/abp.commercial.ng.ui/config';
import { eThemeLeptonComponents } from '../../enums/components';

@Component({
  selector: 'abp-current-user',
  // tslint:disable-next-line: component-max-inline-declarations
  templateUrl: 'current-user.component.html',
})
export class CurrentUserComponent {
  currentUser$ = this.configState.getOne$('currentUser');

  selectedTenant$ = this.sessionState.getTenant$();

  currentUserImageComponentKey = eThemeLeptonComponents.CurrentUserImage;

  get smallScreen(): boolean {
    return window.innerWidth < 992;
  }

  get issuer() {
    return this.environment.getEnvironment().oAuthConfig.issuer;
  }

  constructor(
    @Inject(NAVIGATE_TO_MANAGE_PROFILE) public navigateToManageProfile,
    @Inject(NAVIGATE_TO_MY_SECURITY_LOGS) public navigateToMySecurityLogs,
    @Optional() @Inject(OPEN_MY_LINK_USERS_MODAL) public openMyLinkUsersModal,
    private authService: AuthService,
    private sessionState: SessionStateService,
    private configState: ConfigStateService,
    private environment: EnvironmentService,
  ) {}

  navigateToLogin() {
    this.authService.navigateToLogin();
  }

  logout() {
    this.authService.logout().subscribe();
  }
}
