<form *ngIf="!showCodeForm; else codeFormTmp">
  <p>{{ 'AbpAccount::SendSecurityCode_Information' | abpLocalization }}</p>

  <div class="form-group">
    <label for="selected-provider">{{ 'AbpAccount::SelectedProvider' | abpLocalization }}</label>
    <select
      class="custom-select form-control"
      id="selected-provider"
      name="selected-provider"
      [(ngModel)]="selectedProvider"
    >
      <option *ngFor="let provider of providers" [ngValue]="provider">{{ provider }}</option>
    </select>
  </div>

  <abp-button (abpClick)="sendTwoFactorCode()" [loading]="loading">
    {{ 'AbpAccount::Submit' | abpLocalization }}
  </abp-button>
</form>

<ng-template #codeFormTmp>
  <form [formGroup]="codeForm" (ngSubmit)="login()">
    <p>{{ 'AbpAccount::VerifySecurityCode_Information' | abpLocalization }}</p>

    <div class="form-group">
      <label for="code">{{ 'AbpAccount::Code' | abpLocalization }}</label>
      <input
        class="form-control"
        type="text"
        id="code"
        formControlName="code"
        autocomplete="off"
        autofocus
      />
    </div>

    <abp-button buttonType="submit" [loading]="loading">
      {{ 'AbpAccount::Submit' | abpLocalization }}
    </abp-button>
  </form>
</ng-template>
