<h2>{{ 'LeptonThemeManagement::Menu:LeptonThemeSettings' | abpLocalization }}</h2>
<hr class="my-4" />
<form id="LeptonThemeSettingsForm" novalidate="novalidate" [formGroup]="form">
  <div class="row">
    <div class="col col-md-12">
      <div>
        <div class="form-group" *ngIf="!customStyle">
          <label for="Style">{{
            'LeptonThemeManagement::DisplayName:Style' | abpLocalization
          }}</label>
          <select
            class="custom-select form-control valid"
            id="Style"
            name="Style"
            formControlName="style"
          >
            <option [ngValue]="0">Style1</option>
            <option [ngValue]="1">Style2</option>
            <option [ngValue]="2">Style3</option>
            <option [ngValue]="3">Style4</option>
            <option [ngValue]="4">Style5</option>
            <option [ngValue]="5">Style6</option>
          </select>

          <span
            class="text-danger field-validation-valid"
            data-valmsg-for="Style"
            data-valmsg-replace="true"
          ></span>
        </div>
        <div class="form-group">
          <label for="PublicLayoutStyle">{{
            'LeptonThemeManagement::DisplayName:PublicLayoutStyle' | abpLocalization
          }}</label>
          <select
            class="custom-select form-control valid"
            id="PublicLayoutStyle"
            name="PublicLayoutStyle"
            formControlName="publicLayoutStyle"
          >
            <option [ngValue]="0">Style1</option>
            <option [ngValue]="1">Style2</option>
            <option [ngValue]="2">Style3</option>
            <option [ngValue]="3">Style4</option>
            <option [ngValue]="4">Style5</option>
            <option [ngValue]="5">Style6</option>
          </select>

          <span
            class="text-danger field-validation-valid"
            data-valmsg-for="PublicLayoutStyle"
            data-valmsg-replace="true"
          ></span>
        </div>
        <ng-container *ngIf="!isSmallScreen">
          <div class="form-group">
            <label for="MenuPlacement">{{
              'LeptonThemeManagement::DisplayName:MenuPlacement' | abpLocalization
            }}</label>
            <select
              class="custom-select form-control valid"
              id="MenuPlacement"
              name="MenuPlacement"
              formControlName="menuPlacement"
            >
              <option [ngValue]="placements.Left">
                {{ 'LeptonThemeManagement::Left' | abpLocalization }}
              </option>
              <option [ngValue]="placements.Top">
                {{ 'LeptonThemeManagement::Top' | abpLocalization }}
              </option>
            </select>
          </div>
          <div
            class="form-group"
            [@collapse]="
              form.value.menuPlacement === placements.Left
                ? viewInitialized
                  ? 'expanded'
                  : ''
                : 'collapsed'
            "
          >
            <label for="MenuStatus">{{
              'LeptonThemeManagement::DisplayName:MenuStatus' | abpLocalization
            }}</label>
            <select
              class="custom-select form-control valid"
              id="MenuStatus"
              name="MenuStatus"
              formControlName="menuStatus"
            >
              <option [ngValue]="statuses.AlwaysOpened">
                {{ 'LeptonThemeManagement::AlwaysOpened' | abpLocalization }}
              </option>
              <option [ngValue]="statuses.OpenOnHover">
                {{ 'LeptonThemeManagement::OpenOnHover' | abpLocalization }}
              </option>
            </select>
          </div>
          <div class="custom-checkbox custom-control mb-2">
            <input
              type="checkbox"
              id="BoxedLayout"
              name="BoxedLayout"
              value="true"
              class="custom-control-input"
              formControlName="boxedLayout"
            /><label class="custom-control-label" for="BoxedLayout">{{
              'LeptonThemeManagement::DisplayName:BoxedLayout' | abpLocalization
            }}</label>
          </div>
        </ng-container>
      </div>
      <hr class="my-4" />
      <div>
        <abp-button buttonType="button" (click)="save()" [loading]="loading" iconClass="fa fa-save"
          >{{ 'LeptonThemeManagement::Save' | abpLocalization }}
        </abp-button>
      </div>
    </div>
  </div>
</form>
