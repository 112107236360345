import { Environment } from '@abp/ng.core';

const baseUrl = 'https://portal-dev.healthvine.com';

export const environment = {
  production: false,
  application: {
    baseUrl,
    name: 'Simplinity',
  },
  oAuthConfig: {
    issuer: 'https://api-dev.healthvine.com',
    redirectUri: baseUrl,
    clientId: 'Simplinity_App',
    responseType: 'code',
    scope: 'offline_access Simplinity',
    requireHttps: true
  },
  apis: {
    default: {
      url: 'https://api-dev.healthvine.com',
      rootNamespace: 'Nexteq.Simplinity',
    },
  },
} as Environment;
