<ng-container *ngIf="currentUser$ | async as user">
  <ng-template #loginBtn>
    <a role="button" class="btn" (click)="navigateToLogin()">
      <i class="fas fa-sign-in-alt mr-1"></i>
      {{ 'AbpAccount::Login' | abpLocalization }}</a
    >
  </ng-template>
  <div *ngIf="user.isAuthenticated; else loginBtn" class="dropdown btn-group" ngbDropdown>
    <a ngbDropdownToggle class="btn pointer d-flex">
      <abp-current-user-image
        *abpReplaceableTemplate="{
          componentKey: currentUserImageComponentKey,
          inputs: { currentUser: { value: user }, classes: { value: 'user-avatar' } }
        }"
        classes="user-avatar"
      ></abp-current-user-image>
      <span class="ml-1">
        <small *ngIf="(selectedTenant$ | async)?.name as tenantName"
          ><i>{{ tenantName }}</i
          >\</small
        >
        <span>{{ user.userName }}</span>
      </span>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
      <div class="p-2 row">
        <div class="pr-0 col col-auto">
          <abp-current-user-image
            *abpReplaceableTemplate="{
              componentKey: currentUserImageComponentKey,
              inputs: { currentUser: { value: user }, classes: { value: 'user-avatar-big' } }
            }"
            classes="user-avatar-big"
          ></abp-current-user-image>
        </div>
        <div class="pl-2 col">
          <span>{{ 'AbpAccount::Welcome' | abpLocalization }}</span
          ><br />
          <small *ngIf="(selectedTenant$ | async)?.name as tenantName"
            ><i>{{ tenantName }}</i
            >\</small
          >
          <strong>{{
            ((user.name || '') + ' ' + (user.surName || '')).trim() || user.userName
          }}</strong>
        </div>
      </div>
      <div class="dropdown-divider"></div>
      <a
        *ngIf="openMyLinkUsersModal"
        class="dropdown-item pointer"
        (click)="openMyLinkUsersModal()"
        >{{ 'AbpAccount::LinkedAccounts' | abpLocalization }}</a
      >
      <a class="dropdown-item pointer" (click)="navigateToManageProfile()">{{
        'AbpAccount::MyAccount' | abpLocalization
      }}</a>
      <a class="dropdown-item pointer" (click)="navigateToMySecurityLogs()">{{
        'AbpAccount::MySecurityLogs' | abpLocalization
      }}</a>
      <a class="dropdown-item pointer" id="logout" (click)="logout()">{{
        'AbpUi::Logout' | abpLocalization
      }}</a>
    </div>
  </div>
</ng-container>
