import { noop, SubscriptionService } from '@abp/ng.core';
import { collapse, ToasterService } from '@abp/ng.theme.shared';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Layout } from '../../models/layout';
import { LayoutStateService } from '../../services/layout-state.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { throwError } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import { CUSTOM_STYLE } from '../../tokens/custom-style.token';

@Component({
  selector: 'abp-lepton-settings',
  templateUrl: './settings.component.html',
  providers: [LayoutService],
  animations: [collapse],
})
export class SettingsComponent implements OnInit, AfterViewInit {
  placements = { Left: Layout.MenuPlacement.Left, Top: Layout.MenuPlacement.Top };

  statuses = {
    AlwaysOpened: Layout.MenuStatus.AlwaysOpened,
    OpenOnHover: Layout.MenuStatus.OpenOnHover,
  };

  boxedLayout: boolean = false;

  loading: boolean;

  viewInitialized: boolean = false;

  form: FormGroup;

  get isSmallScreen(): boolean {
    return window.innerWidth < 992;
  }

  constructor(
    private toaster: ToasterService,
    private router: Router,
    private subscription: SubscriptionService,
    private layoutStateService: LayoutStateService,
    private layout: LayoutService,
    private fb: FormBuilder,
    @Inject(CUSTOM_STYLE) public customStyle: boolean,
  ) {
    this.form = this.fb.group({
      ...(!customStyle && { style: [] }),
      publicLayoutStyle: [],
      menuPlacement: [],
      menuStatus: [],
      boxedLayout: [],
    });
  }

  ngOnInit(): void {
    this.layout.fetchThemeSettings();

    this.subscription.addOne(
      this.layoutStateService.get$('themeSettings').pipe(filter(Boolean)),
      ({
        boxedLayout,
        menuPlacement,
        menuStatus,
        style,
        publicLayoutStyle,
      }: Layout.ThemeSettings) => {
        this.form.patchValue({
          menuPlacement,
          boxedLayout,
          ...(!this.customStyle && { style: style || 0 }),
          publicLayoutStyle: publicLayoutStyle || 0,
          menuStatus: !menuStatus && menuStatus !== 0 ? Layout.MenuStatus.AlwaysOpened : menuStatus,
        });
      },
    );
  }

  ngAfterViewInit() {
    setTimeout(() => (this.viewInitialized = true), 0);
  }

  save() {
    this.loading = true;
    const successFn = async () => {
      const { shouldReuseRoute } = this.router.routeReuseStrategy;

      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.navigated = false;

      await this.router.navigateByUrl(this.router.url).catch(noop);
      this.router.routeReuseStrategy.shouldReuseRoute = shouldReuseRoute;
      this.loading = false;
      setTimeout(() => {
        this.toaster.success('LeptonThemeManagement::SuccessfullySaved', null);
      }, 0);
    };
    const errorFn = e => {
      this.loading = false;
      return throwError(e);
    };
    this.layout.updateThemeSettings(this.form.value, successFn, errorFn);
  }
}
