import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { eThemeLeptonComponents } from '../../enums';
import { AuthService, ConfigStateService, SessionStateService, SubscriptionService } from '@abp/ng.core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { LayoutStateService } from '../../services/layout-state.service';

@Component({
  selector: 'abp-header',
  templateUrl: './header.component.html',
  providers: [SubscriptionService],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements AfterViewInit {
  isNavbarExpanded$ = this.layoutStateService.get$('isNavbarExpanded');
  isMenuExpanded$ = this.layoutStateService.get$('isMenuExpanded');
  smallScreen$ = this.layoutStateService.get$('smallScreen');
  isMenuPlacementTop$ = this.layoutStateService.get$('isMenuPlacementTop');
  logoComponentKey = eThemeLeptonComponents.Logo;
  navbarComponentKey = eThemeLeptonComponents.Navbar;
  navbarMobileComponentKey = eThemeLeptonComponents.NavbarMobile;
  sidebarComponentKey = eThemeLeptonComponents.Sidebar;

  legacyHeader = false;

  currentUser$ = this.configState.getOne$('currentUser');

  selectedTenant$ = this.sessionState.getTenant$();

  @Output()
  mouseMoveContainer = new EventEmitter<Array<ElementRef<HTMLElement>>>();

  @ViewChild('navbarBrand', { read: ElementRef })
  navbarBrandRef: ElementRef<any>;
  constructor(
    private authService: AuthService,
    private subscription: SubscriptionService,
    private sessionState: SessionStateService,
    private renderer: Renderer2,
    private store: Store,
    private layoutStateService: LayoutStateService,
    private route: ActivatedRoute,
    private configState: ConfigStateService,
  ) {
  }

  ngAfterViewInit() {

    this.route.queryParams
    .subscribe(params => {
      if(params.__test === "header"){
        this.legacyHeader = true;
      }
    });
    this.mouseMoveContainer.next([this.navbarBrandRef]);
  }

  navbarIconClick(isNavbarExpanded: boolean) {
    this.layoutStateService.patch({ isNavbarExpanded });
  }

  menuIconClick(isMenuExpanded: boolean) {
    this.layoutStateService.patch({ isMenuExpanded });
  }

  navbarLogoutClick(){  
    this.authService.logout();
  }
}