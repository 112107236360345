<div id="wrapper">
  <abp-page [title]="'AbpAccount::MySecurityLogs' | abpLocalization" [toolbar]="data.items">
    <div class="card">
      <div class="card-body pb-lg-3">
        <div class="row" (keyup.enter)="list.get()">
          <div class="col-md-6 col-lg-3">
            <div class="form-group">
              <label>{{ 'AbpAccount::MySecurityLogs:StartTime' | abpLocalization }}</label>
              <input
                #startDate="ngbDatepicker"
                class="form-control"
                name="start-date"
                readonly
                [(ngModel)]="filter.startTime"
                [dayTemplate]="startDateTemplate"
                ngbDatepicker
                (click)="startDate.toggle()"
              />
              <ng-template
                #startDateTemplate
                let-date
                let-focused="focused"
                let-selected="selected"
                let-currentMonth="currentMonth"
              >
                <div
                  class="btn-light custom-day"
                  [class.bg-primary]="selected"
                  [class.text-white]="selected"
                  [class.text-muted]="currentMonth !== date.month"
                  [class.ng-range]="date.after(filter.startTime) && date.before(filter.endTime)"
                  [class.ng-range-end]="date.equals(filter.endTime)"
                >
                  {{ date.day }}
                </div>
              </ng-template>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="form-group">
              <label>{{ 'AbpAccount::MySecurityLogs:EndTime' | abpLocalization }}</label>
              <input
                #endDate="ngbDatepicker"
                class="form-control"
                name="end-date"
                readonly
                [(ngModel)]="filter.endTime"
                [dayTemplate]="endDateTemplate"
                ngbDatepicker
                (click)="endDate.toggle()"
              />
              <ng-template
                #endDateTemplate
                let-date
                let-focused="focused"
                let-selected="selected"
                let-currentMonth="currentMonth"
              >
                <div
                  class="btn-light custom-day"
                  [class.bg-primary]="selected"
                  [class.text-white]="selected"
                  [class.text-muted]="currentMonth !== date.month"
                  [class.ng-range]="date.after(filter.startTime) && date.before(filter.endTime)"
                  [class.ng-range-end]="date.equals(filter.startTime)"
                >
                  {{ date.day }}
                </div>
              </ng-template>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="form-group">
              <label>{{ 'AbpAccount::MySecurityLogs:Action' | abpLocalization }}</label>
              <input type="text" class="form-control" [(ngModel)]="filter.action" />
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <button (click)="list.get()" class="mt-md-4 btn btn-primary btn-block">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <abp-extensible-table
      [data]="data.items"
      [recordsTotal]="data.totalCount"
      [list]="list"
    ></abp-extensible-table>
  </abp-page>
</div>
