<div class="error-page-container">
  <div class="row">
    <div class="col col-auto">
      <div class="status-icon">
        <i class="fa fa-frown-o text-danger" aria-hidden="true"></i>
        <span></span>
      </div>
    </div>
    <div class="col">
      <div class="status-content">
        <h1>{{ errorStatus }}</h1>
        <h2 class="text-danger mb-0">
          {{ errorStatusText | abpLocalization
          }}<small class="text-muted">{{ errorDetail | abpLocalization }}</small>
        </h2>
        <p class="mt-3 mb-4"></p>
        <a (click)="destroy$.next()" routerLink="/" class="btn btn-primary">{{
          'AbpUi::GoHomePage' | abpLocalization
        }}</a>
        <a href="javascript:void(0)" class="mt-4 mb-1 d-block" (click)="destroy$.next()"
          ><i class="fa fa-long-arrow-left mr-1"></i>{{ 'AbpUi::GoBack' | abpLocalization }}</a
        >
      </div>
    </div>
  </div>
</div>
