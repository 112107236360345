import { ReplaceableComponentsService } from '@abp/ng.core';
import { Component } from '@angular/core';
import { eAccountComponents } from '@volo/abp.ng.account/public';
import { CustomEmailConfirmationComponent } from './account/custom-email-confirmation.component';
import { CustomResetPasswordComponent } from './account/custom-reset-password.component';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent {
  constructor(
    private replaceableComponents: ReplaceableComponentsService, // injected the service
  ){
    // Ref -> https://docs.abp.io/en/abp/4.4/UI/Angular/Component-Replacement#how-to-replace-a-component
    this.replaceableComponents.add({
      component: CustomEmailConfirmationComponent,
      key: eAccountComponents.EmailConfirmation,
    });

    this.replaceableComponents.add({
      component: CustomResetPasswordComponent,
      key: eAccountComponents.ResetPassword,
    });
  }
}
