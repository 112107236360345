import { PagedResultDto, RestOccurError, RestService } from '@abp/ng.core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  IdentitySecurityLogDto,
  IdentitySecurityLogGetListInput,
} from '@volo/abp.commercial.ng.ui/config';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import type {
  ConfirmEmailInput,
  ConfirmPhoneNumberInput,
  GetTwoFactorProvidersInput,
  IFormFile,
  ProfilePictureInput,
  ProfilePictureSourceDto,
  RegisterDto,
  ResetPasswordDto,
  SendEmailConfirmationTokenDto,
  SendPasswordResetCodeDto,
  SendPhoneNumberConfirmationTokenDto,
  SendTwoFactorCodeInput,
} from '../models/account';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  apiName = 'AbpAccountPublic';

  get idsUrl() {
    return this.oAuthService.issuer;
  }

  confirmEmail = (input: ConfirmEmailInput) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/account/confirm-email`,
        body: input,
      },
      { apiName: this.apiName },
    );

  confirmPhoneNumber = (input: ConfirmPhoneNumberInput) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/account/confirm-phone-number`,
        body: input,
      },
      { apiName: this.apiName },
    );

  getProfilePicture = (id: string) =>
    this.restService.request<any, ProfilePictureSourceDto>(
      {
        method: 'GET',
        url: `/api/account/profile-picture/${id}`,
      },
      { apiName: this.apiName },
    );

  getProfilePictureFile = (id: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/account/profile-picture-file/${id}`,
      },
      { apiName: this.apiName },
    );

  register = (input: RegisterDto) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `/api/account/register`,
        body: input,
      },
      { apiName: this.apiName },
    );

  resetPassword = (input: ResetPasswordDto) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/account/reset-password`,
        body: input,
      },
      { apiName: this.apiName },
    );

  sendEmailConfirmationToken = (input: SendEmailConfirmationTokenDto) =>
    this.restService.request<SendEmailConfirmationTokenDto, void>(
      {
        method: 'POST',
        url: `/api/account/send-email-confirmation-token`,
        body: input,
      },
      { apiName: this.apiName },
    );

  sendPasswordResetCode = (input: SendPasswordResetCodeDto) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/account/send-password-reset-code`,
        body: input,
      },
      { apiName: this.apiName },
    );

  sendPhoneNumberConfirmationToken = (input: SendPhoneNumberConfirmationTokenDto) =>
    this.restService.request<SendPhoneNumberConfirmationTokenDto, void>(
      {
        method: 'POST',
        url: `/api/account/send-phone-number-confirmation-token`,
        body: input,
      },
      { apiName: this.apiName },
    );

  setProfilePicture = (input: ProfilePictureInput) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/account/profile-picture`,
        body: input,
      },
      { apiName: this.apiName },
    );

  uploadProfilePictureFile = (image: IFormFile) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `/api/account/profile-picture-file`,
      },
      { apiName: this.apiName },
    );

  getMySecurityLogsByInput(
    params = {} as Partial<IdentitySecurityLogGetListInput>,
  ): Observable<PagedResultDto<IdentitySecurityLogDto>> {
    return this.restService.request(
      { url: '/api/identity/security-logs/my', method: 'GET', params },
      { apiName: this.apiName },
    );
  }

  recaptchaByCaptchaResponse = (captchaResponse: string) =>
    this.restService.request<any, void>(
      {
        method: 'GET',
        url: '/api/account/recaptcha-validate',
        params: { captchaResponse },
      },
      { apiName: this.apiName },
    );

  getTwoFactorProviders = (input: GetTwoFactorProvidersInput) => {
    return this.http
      .get<string[]>(
        `${this.idsUrl}/api/account/two-factor-providers?userId=${
          input.userId
        }&token=${encodeURIComponent(input.token)}`,
      )
      .pipe(catchError(this.handleError));
  };

  sendTwoFactorCode = (input: SendTwoFactorCodeInput) => {
    return this.http
      .post<SendTwoFactorCodeInput>(`${this.idsUrl}/api/account/send-two-factor-code`, input)
      .pipe(catchError(this.handleError));
  };

  private handleError = err => {
    this.store.dispatch(new RestOccurError(err));
    return throwError(err);
  };

  constructor(
    private restService: RestService,
    private http: HttpClient,
    private oAuthService: OAuthService,
    private store: Store,
  ) {}
}
