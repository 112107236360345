import { ABP, TreeNode } from '@abp/ng.core';
import { Injectable, Type } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import {
  eAccountManageProfileTabNames,
  ManageProfileTabsService,
} from '@volo/abp.ng.account/public/config';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { PersonalSettingsComponent } from '../components/personal-settings/personal-settings.component';
import { ProfilePictureComponent } from '../components/profile-picture/profile-picture.component';
import { TwoFactorTabComponent } from '../components/two-factor-tab/two-factor-tab/two-factor-tab.component';

@Injectable()
export class ManageProfileResolver {
  constructor(private manageProfileTabs: ManageProfileTabsService) {}

  resolve() {
    return this.manageProfileTabs.visible$.pipe(tap(this.addComponentsToTabs), take(1));
  }

  private addComponentsToTabs = (tabs: TreeNode<Omit<ABP.Tab, 'parentName'>>[]) => {
    tabs.forEach(tab => {
      if (tab.component) return;
      let component: Type<any>;
      switch (tab.name) {
        case eAccountManageProfileTabNames.ProfilePicture:
          component = ProfilePictureComponent;
          break;
        case eAccountManageProfileTabNames.ChangePassword:
          component = ChangePasswordComponent;
          break;
        case eAccountManageProfileTabNames.PersonalInfo:
          component = PersonalSettingsComponent;
          break;
        case eAccountManageProfileTabNames.TwoFactor:
          component = TwoFactorTabComponent;
          break;
        default:
          return;
      }

      this.manageProfileTabs.patch(tab.name, { component });
    });
  };
}
