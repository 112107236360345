import { DomInsertionService, DOM_STRATEGY, StyleContentStrategy } from '@abp/ng.core';
import { Injector } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { LayoutStateService } from '../services/layout-state.service';

export const getLeptonStyle = (type: number): Observable<string> => {
  const leptonJs = import(
    /* webpackChunkName: "theme.[request]" */
    /* webpackMode: "lazy" */
    `@volo/abp.ng.theme.lepton/dist/global/styles/lepton${type}`
  );

  return from(leptonJs).pipe(map(m => m.default));
};

export function setStyle(styleNumber: number, injector: Injector) {
  const layoutState = injector.get(LayoutStateService);
  if (layoutState.get('style') === styleNumber || typeof styleNumber !== 'number') {
    return;
  }
  loadLeptonStyle(styleNumber, injector)
    .pipe(take(1), tap(removeLeptonLoader))
    .subscribe(element => {
      const styleElement = layoutState.get('styleElement');
      removeLeptonStyles(styleElement, injector);
      layoutState.patch({ style: styleNumber, styleElement: element });
    });
}

function loadLeptonStyle(type: number, injector: Injector): Observable<HTMLStyleElement> {
  const domInsertionService = injector.get(DomInsertionService);
  const bootstrap: HTMLLinkElement = document.querySelector('link[href*=bootstrap]');
  const domStrategy = bootstrap
    ? DOM_STRATEGY.AfterElement(bootstrap)
    : DOM_STRATEGY.AppendToHead();

  return getLeptonStyle(type).pipe(
    map(content => {
      const strategy = new StyleContentStrategy(content, domStrategy);
      return domInsertionService.insertContent(strategy);
    }),
  );
}

function removeLeptonStyles(element: HTMLStyleElement, injector: Injector) {
  const domInsertionService = injector.get(DomInsertionService);
  if (element) domInsertionService.removeContent(element);
}

export function removeLeptonLoader() {
  const loader: HTMLElement = document.querySelector('#lp-page-loader');
  if (!loader) return;
  loader.style.background = 'var(--background)';
  setTimeout(() => loader.parentNode?.removeChild(loader), 500);
}
