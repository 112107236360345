import {
  LoginParams,
  pipeToLogin,
  SessionStateService,
  WebHttpUrlEncodingCodec,
} from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { OAuthService, OAuthStorage, TokenResponse } from 'angular-oauth2-oidc';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import snq from 'snq';

export interface SecurityCodeData extends LoginParams {
  userId: string;
  twoFactorToken: string;
}

@Injectable()
export class SecurityCodeService {
  data: SecurityCodeData;

  constructor(
    @Inject(OAuthStorage) private storage,
    private injector: Injector,
    private oAuthService: OAuthService,
    private http: HttpClient,
    private sessionState: SessionStateService,
    private toaster: ToasterService,
  ) {}

  login(twoFactor: { provider: string; code: string }) {
    // Note: check this method when angular-oauth2-oidc package is updated.
    const { tokenEndpoint, clientId, dummyClientSecret, scope } = this.oAuthService;
    const { username, password } = this.data;
    const tenant = this.sessionState.getTenant();

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    if (tenant?.id) {
      headers = headers.append('__tenant', tenant.id);
    }

    const params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() })
      .set('grant_type', 'password')
      .set('client_id', clientId)
      .set('client_secret', dummyClientSecret)
      .set('scope', scope)
      .set('UserName', username)
      .set('Password', password)
      .set('TwoFactorProvider', twoFactor.provider)
      .set('TwoFactorCode', twoFactor.code);

    return this.http
      .post<TokenResponse>(tokenEndpoint, params, {
        headers,
      })
      .pipe(
        tap((res: TokenResponse) => {
          const { access_token, refresh_token, scope: grantedScopes, expires_in } = res;

          this.storage.setItem('access_token', access_token);
          this.storage.setItem('refresh_token', refresh_token);
          this.storage.setItem('access_token_stored_at', '' + Date.now());

          if (grantedScopes) {
            this.storage.setItem('granted_scopes', JSON.stringify(grantedScopes.split(' ')));
          }

          if (expires_in) {
            const expiresInMilliSeconds = expires_in * 1000;
            const now = new Date();
            const expiresAt = now.getTime() + expiresInMilliSeconds;
            this.storage.setItem('expires_at', '' + expiresAt);
          }
        }),
        pipeToLogin(
          { redirectUrl: this.data.redirectUrl, rememberMe: this.data.rememberMe },
          this.injector,
        ),
        catchError(err => {
          this.toaster.error(
            snq(() => err.error.error_description) ||
              snq(() => err.error.error.message, 'AbpAccount::DefaultErrorMessage'),
            'Error',
            { life: 7000 },
          );
          return throwError(err);
        }),
      );
  }
}
