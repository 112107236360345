<div class="account-header fixed-top p-3"></div>
<div class="d-flex align-items-center" style="min-height: 100vh">
  <div class="container">
    <abp-page-alert-container></abp-page-alert-container>
    <abp-auth-wrapper
      *abpReplaceableTemplate="{
        componentKey: authWrapperKey
      }"
    >
      <router-outlet></router-outlet>
    </abp-auth-wrapper>
  </div>
</div>
