import { getPasswordValidators } from '@abp/ng.theme.shared';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { comparePasswords, Validation } from '@ngx-validate/core';
import { AccountService } from 'projects/account/public/src/services/account.service';
import { finalize } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

const PASSWORD_FIELDS = ['password', 'confirmPassword'];

@Component({
  selector: 'abp-reset-password',
  templateUrl: './custom-reset-password.component.html',
})
export class CustomResetPasswordComponent implements OnInit {
  link: SafeUrl;
  isPatientsTenant: boolean;
  resetToken: string;
  userId: string;
  form: FormGroup;
  inProgress: boolean = false;
  isPasswordReset: boolean = false;

  mapErrorsFn: Validation.MapErrorsFn = (errors, groupErrors, control) => {
    if (PASSWORD_FIELDS.indexOf(String(control.name)) < 0) return errors;

    return errors.concat(groupErrors.filter(({ key }) => key === 'passwordMismatch'));
  };

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((
      { 
        userId, 
        resetToken,
        tenant,
        link 
      }) => {
     
      console.log({ 
        userId, 
        resetToken,
        tenant,
        link 
      });
        
      if(tenant === "Patients"){
        this.isPatientsTenant = true;
        var unsafeLink = this.FormatLink(link, userId, resetToken);
        this.link = this.sanitizer.bypassSecurityTrustUrl(unsafeLink);

        // Try to redirect automatically to mobile application 
        window.location.replace(unsafeLink);
      }
      else {
        // Default implementation
        if (!userId || !resetToken) this.router.navigateByUrl('/account/login');

        this.form = this.fb.group(
          {
            userId: [userId, [Validators.required]],
            resetToken: [resetToken, [Validators.required]],
            password: ['', [Validators.required, ...getPasswordValidators(this.injector)]],
            confirmPassword: ['', [Validators.required, ...getPasswordValidators(this.injector)]],
          },
          {
            validators: [comparePasswords(PASSWORD_FIELDS)],
          },
        );
      }
    });
  }
  FormatLink(link: any, userId: any, resetToken: any): string {
    
    var formatedLink = link.replace("$", "?").replace("_", "&")

    if(formatedLink.includes("?")){
      // Already has params
      formatedLink = `${formatedLink}&`
    }
    else {
      // Has no params
      formatedLink = `${formatedLink}?`
    }

    return `${formatedLink}resetToken=${resetToken}&userId=${userId}`
  }


  formatLink(link: string): string {
    return link.replace("$", "?")
              .replace("_", "&")
  }

  onSubmit() {
    if (this.form.invalid || this.inProgress) return;

    this.inProgress = true;

    this.accountService
      .resetPassword({
        userId: this.form.get('userId').value,
        resetToken: this.form.get('resetToken').value,
        password: this.form.get('password').value,
      })
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe(() => {
        this.isPasswordReset = true;
      });
  }
}
