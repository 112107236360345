import {
  ConfigStateService,
  CONTENT_STRATEGY,
  CORE_OPTIONS,
  DomInsertionService,
  ReplaceableComponentsService,
} from '@abp/ng.core';
import { APP_INITIALIZER, Injector, RendererFactory2 } from '@angular/core';
import { AccountLayoutComponent } from '../components/account-layout/account-layout.component';
import { ApplicationLayoutComponent } from '../components/application-layout/application-layout.component';
import { EmptyLayoutComponent } from '../components/empty-layout/empty-layout.component';
import styles from '../constants/styles';
import { eThemeLeptonComponents } from '../enums/components';
import { Layout } from '../models/layout';
import { removeLeptonLoader, setStyle } from '../utils/layout.utils';
import { LayoutService } from '../services/layout.service';
import { CUSTOM_STYLE } from '../tokens/custom-style.token';

export const LEPTON_THEME_STYLES_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureStyles,
    deps: [Injector],
    multi: true,
  },
];

export function configureStyles(injector: Injector) {
  return () => {
    const rendererFactory = injector.get(RendererFactory2);
    const domInsertion = injector.get(DomInsertionService);
    const replaceableComponents = injector.get(ReplaceableComponentsService);
    const configState = injector.get(ConfigStateService);
    const layoutService = injector.get(LayoutService);
    const customStyle = injector.get(CUSTOM_STYLE);

    rendererFactory
      .createRenderer(document.body, null)
      .addClass(document.body, 'abp-application-layout');
    domInsertion.insertContent(CONTENT_STRATEGY.AppendStyleToHead(styles));

    listenToLeptonSettings(layoutService, configState);

    if (!customStyle) {
      loadLeptonStyles(injector, configState);
    } else {
      removeLeptonLoader();
    }

    initLayouts(replaceableComponents);
  };
}

function loadLeptonStyles(injector: Injector, configState: ConfigStateService) {
  const coreOptions = injector.get(CORE_OPTIONS);
  const setStyleCb = createSetStyle(injector, configState);
  if (coreOptions.skipGetAppConfiguration) setStyleCb();

  configState
    .createOnUpdateStream(state => state)
    .subscribe(() => {
      setStyleCb();
    });
}

function listenToLeptonSettings(layoutService: LayoutService, configState: ConfigStateService) {
  const leptonSettings$ = configState.getSettings$('Lepton');
  const layoutConfigKey = 'Volo.Abp.LeptonTheme.Layout';
  leptonSettings$.subscribe(settings => {
    layoutService.setLayoutBoxed(settings[`${layoutConfigKey}.Boxed`] === 'True');
    layoutService.setMenuStatus(
      Layout.MenuStatus[settings[`${layoutConfigKey}.MenuStatus`] as string] || 0,
    );
    layoutService.setMenuPlacement(
      Layout.MenuPlacement[settings[`${layoutConfigKey}.MenuPlacement`] as string] || 0,
    );
  });
}

function createSetStyle(injector: Injector, configState: ConfigStateService) {
  return () =>
    setStyle(
      Number(
        (configState.getSetting('Volo.Abp.LeptonTheme.Style') || 'Style1').replace('Style', ''),
      ),
      injector,
    );
}

function initLayouts(replaceableComponents: ReplaceableComponentsService) {
  replaceableComponents.add({
    key: eThemeLeptonComponents.ApplicationLayout,
    component: ApplicationLayoutComponent,
  });
  replaceableComponents.add({
    key: eThemeLeptonComponents.AccountLayout,
    component: AccountLayoutComponent,
  });
  replaceableComponents.add({
    key: eThemeLeptonComponents.EmptyLayout,
    component: EmptyLayoutComponent,
  });
}
