<form [formGroup]="form" (ngSubmit)="onSubmit()" [mapErrorsFn]="mapErrorsFn" validateOnSubmit>
  <div *ngIf="!hideCurrentPassword" class="form-group">
    <label for="current-password">{{
      'AbpIdentity::DisplayName:CurrentPassword' | abpLocalization
    }}</label
    ><span> * </span
    ><input
      type="password"
      id="current-password"
      class="form-control"
      formControlName="password"
      autofocus
    />
  </div>
  <div class="form-group">
    <label for="new-password">{{ 'AbpIdentity::DisplayName:NewPassword' | abpLocalization }}</label
    ><span> * </span
    ><input type="password" id="new-password" class="form-control" formControlName="newPassword" />
  </div>
  <div class="form-group">
    <label for="confirm-new-password">{{
      'AbpIdentity::DisplayName:NewPasswordConfirm' | abpLocalization
    }}</label
    ><span> * </span
    ><input
      type="password"
      id="confirm-new-password"
      class="form-control"
      formControlName="repeatNewPassword"
    />
  </div>
  <abp-button
    iconClass="fa fa-check"
    buttonClass="btn btn-primary color-white"
    buttonType="submit"
    [disabled]="form?.invalid"
    >{{ 'AbpIdentity::Save' | abpLocalization }}</abp-button
  >
</form>
