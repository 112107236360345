<ng-container *ngIf="isLoaded; else loading">
  <div class="custom-checkbox custom-control mb-2">
    <input
      type="checkbox"
      class="custom-control-input"
      id="two-factor-enabled"
      [(ngModel)]="isTwoFactorEnabled"
    />
    <label class="custom-control-label" for="two-factor-enabled">{{
      'AbpAccount::DisplayName:TwoFactorEnabled' | abpLocalization
    }}</label>
  </div>
  <abp-button
    iconClass="fa fa-check"
    buttonClass="btn btn-primary mt-2"
    buttonType="submit"
    [loading]="inProgress"
    (click)="setTwoFactorEnabled()"
    >{{ 'AbpAccount::Save' | abpLocalization }}</abp-button
  >
</ng-container>

<ng-template #loading>
  <div [abpLoading]="true"></div>
</ng-template>
