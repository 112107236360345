import { MultiTenancyService } from '@abp/ng.core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'projects/account/public/src/services/account.service';

export interface CustomEmailConfirmationInput {
  confirmationToken: string;
  userId: string;
  name: string;
  surname: string;
  tenant: string;
  link: string;
}

// Ref -> https://docs.abp.io/en/abp/4.4/UI/Angular/Component-Replacement#how-to-replace-a-component
@Component({
  selector: 'abp-email-confirmation',
  template: `

    <div *ngIf="confirmed">
        <div *ngIf="!isPatientsTenant">
            <p *ngIf="confirmed">
            {{ 'AbpAccount::YourEmailAddressIsSuccessfullyConfirmed' | abpLocalization }}
            </p>

            <p class="text-danger font-weight-bold" *ngIf="notValid">
            {{ 'AbpUi::ValidationErrorMessage' | abpLocalization }}
            </p>

            <a role="button" class="btn btn-primary" [routerLink]="['/account/login']">{{
            'AbpAccount::Login' | abpLocalization
            }}</a>
        </div>
        <div *ngIf="isPatientsTenant">
            <p>
                {{ 'AbpAccount::Congratulations' | abpLocalization }} {{name}} {{surname}}!
            </p>
            <p>
                <span>{{ 'AbpAccount::AccountSuccessfullyVerified' | abpLocalization }}. </span>
                <span>{{ 'AbpAccount::Please' | abpLocalization }} </span>
                <a [href]="link">{{ 'AbpAccount::OpenMobileApp' | abpLocalization }} </a>
                <span>{{ 'AbpAccount::LogIn' | abpLocalization }}!</span>
            </p>
            <p>
                {{ 'AbpAccount::Thanks' | abpLocalization }},
            </p>
            <p>
                {{ 'AbpAccount::EmailSender' | abpLocalization }}
            </p>
        </div>
    </div> 
    <p class="text-danger font-weight-bold" *ngIf="notValid">
      {{ 'AbpUi::ValidationErrorMessage' | abpLocalization }}
    </p>
  `,
})
export class CustomEmailConfirmationComponent implements OnInit, OnDestroy {
  name: string 
  surname: string
  link: SafeUrl;
  isPatientsTenant: boolean;
  confirmed: boolean;

  notValid: boolean;

  constructor(
    private multiTenancy: MultiTenancyService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  private resetTenantBox = () => {};

  ngOnInit() {
    const { isTenantBoxVisible } = this.multiTenancy;
    this.resetTenantBox = () => (this.multiTenancy.isTenantBoxVisible = isTenantBoxVisible);

    // throws ExpressionChangedAfterItHasBeenCheckedError without setTimeout
    setTimeout(() => (this.multiTenancy.isTenantBoxVisible = false), 0);

    this.confirmation();
  }

  ngOnDestroy() {
    this.resetTenantBox();
  }

  formatLink(link: string): string {
    return link.replace("$", "?")
              .replace("_", "&")
  }

  confirmation() {
    const { 
      userId, 
      confirmationToken: token,
      name,
      surname,
      tenant,
      link
      } = this.route.snapshot
      .queryParams as CustomEmailConfirmationInput;

    if (!userId || !token) {
      this.notValid = true;
      return;
    }

    this.accountService.confirmEmail({ userId, token }).subscribe(() => {
      this.confirmed = true,
      this.name = name,
      this.surname = surname,
      this.isPatientsTenant = tenant == "Patients"
      var formatedLink = this.formatLink(link);
      this.link = this.sanitizer.bypassSecurityTrustUrl(formatedLink);
    });
  }
}
