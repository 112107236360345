<form *ngIf="!isEmailSent; else emailSentTemplate" [formGroup]="form" (ngSubmit)="onSubmit()" validateOnSubmit>
  <p>{{ 'AbpAccount::SendPasswordResetLink_Information' | abpLocalization }}</p>
  <div class="form-group">
    <label for="input-email-address">{{ 'AbpAccount::EmailAddress' | abpLocalization }}</label
    ><span> * </span>
    <input type="email" id="input-email-address" class="form-control" formControlName="email" />
  </div>
  <abp-button class="d-block" buttonClass="mt-2 mb-3 btn btn-primary btn-block" [loading]="inProgress" buttonType="submit" [disabled]="form?.invalid">
    {{ 'AbpAccount::Submit' | abpLocalization }}
  </abp-button>
  <a routerLink="/account/login"
    ><i class="fa fa-long-arrow-left mr-1"></i>{{ 'AbpAccount::Login' | abpLocalization }}</a
  >
</form>

<ng-template #emailSentTemplate>
  <p>
    {{ 'AbpAccount::PasswordResetMailSentMessage' | abpLocalization }}
  </p>

  <a routerLink="/account/login">
    <button class="d-block mt-2 mb-3 btn btn-primary btn-block">
      <i class="fa fa-long-arrow-left mr-1"></i>
      {{ 'AbpAccount::BackToLogin' | abpLocalization }}
    </button>
  </a>
</ng-template>
