import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const COVERED_ENTITIES_COVERED_ENTITY_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/covered-entities',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:CoveredEntities',
        layout: eLayoutType.application,
        requiredPolicy: 'Simplinity.CoveredEntities',
      },
    ]);
  };
}
